import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "ETS Equals Active Job Seekers",
  "author": "Anthony",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "ets-equals-active-job-seekers",
  "draft": false,
  "meta_title": "ETS Equals Active Job Seekers"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I decided to leave the Army in April of 2006. My 8 years of Active Duty Service would be complete May of 2007, which gave me roughly a year to prepare for my ETS.`}</p>
    <p>{`I didn’t realize I should have begun my job search as soon as I decided to ETS. No one explained to me that my civilian counter parts have their next job lined up before leaving their current job. No one explained to me that I should have been applying for jobs, networking, and discovering how my military skills transferred to specific job opportunities in the private sector.`}</p>
    <p>{`To be honest I figured after leaving the Army I would find a job while taking a break from work. However, being unemployed in the private sector while looking for a job is a red flag for recruiters and employers. Here are some of the stereotypes:`}</p>
    <ul>
      <li parentName="ul">{`The unemployed are lazy`}</li>
      <li parentName="ul">{`The unemployed are incompetent`}</li>
      <li parentName="ul">{`The unemployed don’t want to work`}</li>
      <li parentName="ul">{`Something is wrong with the unemployed`}</li>
      <li parentName="ul">{`The unemployed lack the skills necessary to find work`}</li>
    </ul>
    <p>{`Bottom line, employers believe the best workers are the ones who already have work. If I had known this to be true I would have been actively seeking work while employed by the U.S. Army.`}</p>
    <p>{`So what does being an active job seeker mean? LinkedIn says that an active job seeker or candidate is actively looking for work. This does not mean they're unemployed, but it can. In our case, we’re not unemployed; we’ve just made the decision to transition from the military. The key is now to find work before our ETS date.`}</p>
    <p>{`LinkedIn further `}<a parentName="p" {...{
        "href": "https://business.linkedin.com/talent-solutions/blog/2013/12/recruiting-active-vs-passive-candidates"
      }}>{`explains`}</a>{` that the active job seeker is looking for new work for a variety of reasons:`}</p>
    <ul>
      <li parentName="ul">{`They’re concerned about their current employer’s stability`}</li>
      <li parentName="ul">{`They would like to take more responsibility and grow professionally`}</li>
      <li parentName="ul">{`Their job was outsourced (meaning the job is now held by someone separate from the current company)`}</li>
      <li parentName="ul">{`Their employer went out of business`}</li>
    </ul>
    <p>{`About 25% of the work force falls in this category, so we’re not alone from our civilian counter parts.`}</p>
    <p>{`Now that we know we’re active job seekers there are a few steps to being effective active job seekers.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.purepost.co"
      }}><strong parentName="a">{`Resume`}</strong></a></p>
    <p>{`First, it starts with creating a resume that translates your military experience to civilian terms that recruiters understand and are searching for. Purepost takes care of this for you in about the time it takes to a 1040EZ tax return online. Building your resume has never been simpler, and we support all service branches.`}</p>
    <p>{`A resume is important because it provides an overview of your professional achievements to a recruiter and employer. In many ways, a resume is similar to your ORB or ERB. Like your ORB/ERB, the resume provides a snapshot of your past and current jobs (duty assignments), achievements (awards), and education (training, badges, schools).`}</p>
    <p>{`Your `}<a parentName="p" {...{
        "href": "https://www.purepost.co/users/sign_up"
      }}>{`resume`}</a>{` is:`}</p>
    <ul>
      <li parentName="ul">{`What you will apply to jobs with, online`}</li>
      <li parentName="ul">{`What you will email or take with you, when networking with professionals`}</li>
      <li parentName="ul">{`What you will take with you to all interviews`}</li>
    </ul>
    <p><strong parentName="p">{`Job Search`}</strong></p>
    <p>{`Once you’ve created your resume and understand how your military skills map to private sector skills, you’ll want to begin your job search.`}</p>
    <p>{`Here is where you will want to be strategic in searching for your next career in the private sector. You will search for your job in one of three ways:`}</p>
    <ul>
      <li parentName="ul">{`Location`}</li>
      <li parentName="ul">{`Company`}</li>
      <li parentName="ul">{`And of course Job characteristics`}</li>
    </ul>
    <p>{`When I left the Army, I followed on to complete my Masters in Business Administration. Once I completed my education, I knew living in the San Francisco Bay Area was top priority to succeed in my next career.`}</p>
    <p>{`This told me that I would not be looking for any career opportunities outside of the Bay Area.`}</p>
    <p>{`After I settled on my location, the next most important factor to me was the type of job I wanted. This put the priority of company last and actually allowed for more job opportunities.`}</p>
    <p>{`Once I decided on the type of job I wanted, I compiled a list of all the ideal companies I wanted to work at in the SF Bay Area. This is not as simple as it sounds and takes research to discover what companies where ideal for me. Here are some topics to research that can be discovered online or through `}<a parentName="p" {...{
        "href": "https://www.glassdoor.com"
      }}>{`Glassdoor`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`The product or service provided to the consumer`}</li>
      <li parentName="ul">{`The Culture`}</li>
      <li parentName="ul">{`Company Leadership`}</li>
      <li parentName="ul">{`Salaries`}</li>
      <li parentName="ul">{`Current events and news concerning the company`}</li>
      <li parentName="ul">{`Company Vision, Mission and Goals – what do they ultimate want to accomplish and create in the future`}</li>
      <li parentName="ul">{`Current financial and cultural health of the company – i.e. have they laid employees off recently, how are their products and services doing in relevance to market share – in other words, are customers happy and are the products and service successful`}</li>
    </ul>
    <p>{`I realize this all may seem overwhelming, but the articles here on the Purepost blog provide the guidance you need to break this task down in a manageable way, providing methods for determining the company you want to work at.`}</p>
    <p><strong parentName="p">{`Networking`}</strong></p>
    <p>{`Once you’ve decided the location, job, and company you want to work at, it’s time to network. I’ve chosen to discuss networking before applying for a job because of one main reason – It’s all about who you know in the private sector. By knowing someone who works at a company you desire to work at, or an industry you desire to work in, your chances of finding a job increase. This does not mean you have to network to obtain a job; it just might speed up the process.`}</p>
    <p>{`When I moved to San Francisco I applied to countless jobs. What was surprising was 90% of my interviews came from knowing someone at the company before submitting my job application.`}</p>
    <p>{`For example, I was interested in working at a major health care system located in Oakland. Before applying for the job, I did my research on the company and later reached out to a gentleman, who I’ll call James. James also graduated from the same masters program I had attended. This was a connection I obtained from a friend who I also went to school with.`}</p>
    <p>{`When I met James it was over coffee and he had 30 minutes to meet with me. Prior to our meeting, I had studied the company and knew of 3 jobs I was interested in. James first asked me why I was interested in his company. I explained that my background in the Army was Medical Service, in terms he would understand. I handed him my resume and explained my background. James asked a few questions about my Army experience and I fired back responses similar to being interviewed.`}</p>
    <p>{`He then asked, how can I help? At this point I had brought up the jobs I was interested in and asked questions about these opportunities. I also explained why I was qualified. James explained that he knew people in that department to include the hiring manager (The employee who requested the position you are interviewing for. If you are provided a job offer, and decide to accept, you will be working for the hiring manager). James explained to send him an email with my resume to forward to the hiring manager. This experience resulted in an interview.`}</p>
    <p>{`This happens across all industries and job types. One of my good friends, who had worked in a warehouse at a major grocery company, obtained a job as a forklift operator/driver from networking with my father. My father also worked in the same distribution warehouse and introduced my friend to the hiring manager after filling out a job application. It does not matter what type of skilled worker you are, networking works.`}</p>
    <p><strong parentName="p">{`Applying for a Job`}</strong></p>
    <p>{`I discussed networking in length and believe it helps, but it’s not necessary to get a job. What is necessary is having a resume and filling out a job application. If you’ve never done this it can be a bit challenging and over whelming at first. This is why I recommend having a job search strategy. This will at least allow you to focus on specific jobs and companies to apply to.`}</p>
    <p>{`So what does this process look like? The short answer is you have to apply for a job online. This could be through a companies own website or a job board like Monster.com.`}</p>
    <p>{`Job boards like Monster.com are helpful in your job search.`}</p>
    <p>{`Family Dollar uses Taleo for their application process.`}</p>
    <p>{`Nestle also uses Taleo - this can make it easy when applying to other companies who use Taleo.`}</p>
    <p>{`If you look closely at the Family Dollar’s and Nestle’s job application site, you will notice they are quite similar - that’s because they’re both powered by Teleo. Taleo is a talent software product for companies, which allows a company to manage their job applications. This also makes it easier for job seekers who have already filled out an application under within the Taleo system.`}</p>
    <p>{`For example, if I fill out an application at a company that uses Taleo, I may not have to fill out a complete application or upload my resume to the next company. This definitely makes it easy if you’re applying to similar jobs, however if you’re applying for a different job, it’s best that you start over and complete the application to satisfy the job you’re applying for. This may also require you to upload a different resume with different skills selected. Luckily, Purepost makes this easy to change.`}</p>
    <p>{`Becoming an active job seeker is a job in of-its-own. I know this can seem overwhelming, but it takes practice and it’s best to start as soon as you’ve made the decision to ETS. I’ve found that on average, it can take 3-6 months to find a job, once you’ve fine-tuned your process. Which means you need to treat your job search as a second job.`}</p>
    <p>{`You’ve got incredible experience and skills that transfer perfectly to several career opportunities. Employers respect your service, they know you will learn on the job, and they know you’ve got core values that ensure success. A Purepost profile will assist in translating the rest.`}</p>
    <p>{`Anthony Garcia`}<br parentName="p"></br>{`
`}{`Purepost CEO`}<br parentName="p"></br>{`
`}{`U.S. Army Combat Veteran, Iraq ‘03-’04 and ‘05`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      